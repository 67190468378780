import moment from 'moment';
import React from 'react';

import Tag from '../../components/tag';
import Table from '../../components/table';
import Translate from '../../components/translate';
import MergedTagValue from '../../components/mergedTagValue';

import { dateTimeFormat } from '../../constants/construct';
import invoiceStatuses from '../../constants/invoiceStatuses';
import transactionConstants from '../../constants/transaction';
import campaignStatuses from '../../constants/campaignStatuses';
import invoiceHoldStatuses from '../../constants/invoiceHoldStatuses';

import { idFormatter, screenURL } from '../../helpers';

import translate from '../../utils/translate';
import lazyWithRetry from '../../utils/lazyWithRetry';
import getCampaignStatus from '../../utils/getCampaignStatus';

const Report = lazyWithRetry(() => import('../report').then());

const booleanDropdown = [
    {
        id: 1,
        name: 'Yes',
    },
    {
        id: 2,
        name: 'No',
    },
];

const formatDate = date => moment(date).format(dateTimeFormat);

const renderDelayTag = (row) => {
    const lastSubmissionAlertThreshold = 1440; // minutes
    const submittedOn = moment(row.lastSubmission);
    const periodEnd = moment(row.period.Upper);

    if (!submittedOn.isValid() || !periodEnd.isValid()) {
        return <strong>invalid date</strong>;
    }

    const diff = submittedOn.diff(periodEnd, 'seconds');

    let tagColor = 'error';
    let text = moment.duration(submittedOn.diff(periodEnd)).humanize();

    if (diff <= lastSubmissionAlertThreshold) {
        tagColor = 'success';
        text = translate('on time');

    } else if (diff > 120 && diff < 3600) {
        tagColor = 'warning';
    }

    return (
        <Tag
            name={text}
            color={tagColor}
            tooltip={submittedOn.toString()}
        />
    );
};

const renderLastSubmission = cell => {
    const submittedOn = moment(cell.content);
    const now = moment();
    const diff = now.diff(submittedOn, 'minutes');

    return (diff > 1440) ? (
        <Tag
            size='default'
            color='error'
            name={formatDate(cell.content)}
            tooltip={moment(cell.content).fromNow()}
            icon={{
                size: 16,
                type: 'icon-a-alert-triangle'
            }}
        />
    ) : formatDate(cell.content);
};

const transactionDetailsRenderer = transactionDetails => (
    <Table
        data={transactionDetails?.logDetails}
        keys={[
            {
                span: true,
                name: 'LogID',
                label: 'LogID',
            },
            {
                name: 'RequestLogID',
                label: 'Request Log ID',
                renderer: ({ row }) => row.RequestLogID > 0 ? row.RequestLogID : '',
            },
            {
                span: true,
                name: 'Action',
                label: 'Action'
            },
            {
                name: 'TransactionDetails',
                label: 'Transaction Description',
                renderer: ({ row }) => row.RequestLogID > 0 ? row.TransactionDetails.Description : '',
            },
            {
                name: 'ResponseCode',
                label: 'Response Code',
                renderer: ({ row }) => (row.RequestLogID > 0 && !!row.ResponseCode) ? row.ResponseCode : '',
            },
            {
                name: 'GatewayID',
                label: 'Gateway',
                renderer: ({ cell }) => Object.values(transactionConstants.gateways).find(type => type.id === cell.content)?.name,
            },
            {
                name: 'TransactionDetails',
                label: 'Raw',
                style: {
                    width: '25%',
                },
                renderer: ({ row }) => (
                    <div style={{
                        overflow: 'auto',
                        whiteSpace: 'nowrap',
                        userSelect: 'all',
                    }}>
                        {JSON.stringify(row.TransactionDetails)}
                    </div>
                ),
            },
        ]}
    />
);

const keys = {
    screens: [
        {
            span: true,
            name: 'screenId',
            label: translate('Screen'),
            renderer: ({ cell, row }) => (
                <MergedTagValue
                    target='_blank'
                    url={screenURL(cell.content)}
                    name={idFormatter(cell.content)}
                >
                    {row.screenName}
                </MergedTagValue>
            ),
        },
        {
            name: 'campaignsCount',
            label: translate('Campaigns'),
            renderer: ({ cell }) => (
                <Tag
                    size='default'
                    name={cell.content}
                />
            ),
        },
        {
            name: 'reach',
            label: translate('Reach / Number of Plays'),
            renderer: ({ cell }) => (
                <strong>{cell.content}</strong>
            ),
            totalKey: 'totalReach',
        },
        {
            name: 'estimatedRevenue',
            label: translate('Estimated Revenue'),
            totalKey: 'totalEstimatedPrice',
        },
        {
            name: 'actualRevenue',
            label: translate('Actual Revenue'),
            totalKey: 'totalActualPrice',
        },
    ],
    campaigns: [
        {
            span: true,
            name: 'campaignId',
            label: translate('Campaign'),
            renderer: ({ cell, row }) => (
                <MergedTagValue
                    target='_blank'
                    name={`ID-${cell.content}`}
                    url={`/campaigns?page=1&limit=10&query=${cell.content}`}
                >
                    {row.campaignName}
                </MergedTagValue>
            ),
            style: {
                width: '16%',
            },
        },
        {
            name: 'status',
            label: translate('Status'),
            renderer: ({ cell }) => (
                <Tag
                    size='default'
                    name={getCampaignStatus(cell.content)?.name}
                    color={getCampaignStatus(cell.content)?.color}
                />
            ),
            style: {
                width: '8%',
            },
        },
        {
            name: 'screens',
            label: translate('Screens Count'),
            renderer: ({ cell }) => (
                <Tag
                    size='default'
                    name={cell.content}
                />
            ),
            style: {
                width: '8%',
            },
        },
        {
            name: 'actualReach',
            label: translate('Reach'),
            renderer: ({ cell }) => (
                <strong>{cell.content}</strong>
            ),
            style: {
                width: '10%',
            },
            totalKey: 'totalReach',
        },
        {
            name: 'revenue',
            label: translate('Net Revenue'),
            totalKey: 'revenue',
            style: {
                width: '10%',
            },
        },
        {
            name: 'penalties',
            label: translate('Penalties'),
            totalKey: 'totalPenalty',
            style: {
                width: '10%',
            },
        },
        {
            name: 'refundAmount',
            label: translate('Refund Amount'),
            totalKey: 'totalRefundAmount',
            style: {
                width: '10%',
            },
        },
        {
            name: 'discountAmount',
            label: translate('Discount'),
            totalKey: 'totalDiscountAmount',
            style: {
                width: '10%',
            },
        },
        {
            name: 'totalRevenue',
            label: translate('Gross Revenue'),
            totalKey: 'totalRevenue',
            style: {
                width: '10%',
            },
        },
        {
            name: 'smartCampaignAmount',
            label: translate('Smart Campaign'),
            totalKey: 'totalSmartCampaign',
            style: {
                width: '10%',
            },
        },
        {
            name: 'serviceFeeWorkspace',
            label: translate('Service Fee (Partner)'),
            totalKey: 'totalServiceFeeWorkspace',
            style: {
                width: '10%',
            },
        },
        {
            name: 'agentFee',
            label: translate('Agent Fee'),
            totalKey: 'totalAgentFee',
            style: {
                width: '10%',
            },
        },
        {
            name: 'actualRevenue',
            label: translate('Revenue'),
            totalKey: 'totalActualPrice',
            style: {
                width: '10%',
            },
        },
        {
            name: 'dates',
            label: translate('Dates'),
            style: {
                width: '8%',
            },
        },
    ],
    devices: [
        {
            name: 'workspaceName',
            label: translate('Workspace'),
            style: {
                width: '8%',
            },
            quickFilter: true,
            superAdminOnly: true,
        },
        {
            name: 'workspaceId',
            label: translate('Workspace ID'),
            style: {
                width: '18%',
            },
            canCopy: true,
            quickFilter: true,
            superAdminOnly: true,
        },
        {
            name: 'screenId',
            label: translate('Screen ID'),
            style: {
                width: '8%',
            },
            canCopy: true,
            quickFilter: true
        },
        {
            name: 'screenName',
            label: translate('Screen Name'),
            style: {
                width: '13%',
            },
        },
        {
            name: 'lastSubmission',
            label: translate('Delay'),
            style: {
                width: '8%',
            },
            renderer: ({ row }) => renderDelayTag(row),
        },
        {
            name: 'lastSubmission',
            label: translate('Submitted on'),
            style: {
                width: '10%',
            },
            renderer: ({ cell }) => renderLastSubmission(cell),
        },
        {
            name: 'period',
            label: translate('Period'),
            style: {
                width: '10%',
            },
        },
        {
            name: 'count',
            label: translate('Count'),
            style: {
                width: '5%',
            },
        },
        {
            name: 'statId',
            label: translate('Submission ID'),
            style: {
                width: '6%',
            },
            canCopy: true,
        },
    ],
    invoices: [
        {
            name: 'invoiceId',
            label: translate('Invoice ID'),
            style: {
                width: '8%',
            },
            quickFilter: true,
        },
        {
            name: 'period',
            label: translate('Period'),
            style: {
                width: '10%',
            },
        },
        {
            name: 'userId',
            label: translate('User ID'),
            style: {
                width: '5%',
            },
            quickFilter: true,
        },
        {
            name: 'selfWorkspaceOnly',
            label: translate('Self'),
            style: {
                width: '5%',
            },
            quickFilter: true,
        },
        {
            name: 'statusId',
            label: translate('Status'),
            style: {
                width: '6%',
            },
            renderer: ({ cell }) => {
                const status = Object.values(invoiceStatuses).find(status => status.id === cell.content);

                return (
                    <Tag
                        size='default'
                        name={status?.name}
                        color={status?.color}
                    />
                );
            },
        },
        {
            name: 'chargeAttempt',
            label: translate('Attempt'),
            style: {
                width: '5%',
            },
        },
        {
            name: 'currency',
            label: translate('Currency'),
            style: {
                width: '5%',
            },
        },
        {
            name: 'amount',
            label: translate('Amount'),
            style: {
                width: '8%',
            },
        },
        {
            name: 'isProforma',
            label: translate('Proforma'),
            style: {
                width: '5%',
            },
            renderer: ({ cell }) => (
                <Tag
                    size='default'
                    name={cell.content}
                    color={cell.content === 'Yes' ? 'brand' : 'grey'}
                />
            ),
        },
        {
            name: 'proformaAmount',
            label: translate('Proforma Amount'),
            style: {
                width: '8%',
            },
        },
        {
            name: 'holdStatusId',
            label: translate('Hold Status'),
            style: {
                width: '8%',
            },
            renderer: ({ cell }) => {
                const holdStatus = Object.values(invoiceHoldStatuses).find(status => status.id === cell.content);

                return (
                    <Tag
                        size='default'
                        name={holdStatus?.name}
                        color={holdStatus?.color}
                    />
                );
            },
        },
        {
            name: 'chargeOn',
            label: translate('Charge Date'),
            style: {
                width: '13%',
            },
            renderer: ({ cell }) => (<strong>{(formatDate(cell.content))}</strong>),
        },
        {
            name: 'isLocked',
            label: translate('Locked'),
            style: {
                width: '4%',
            },
            renderer: ({ cell }) => (
                <Tag
                    size='default'
                    name={cell.content}
                    color={cell.content === 'Yes' ? 'error' : 'brand'}
                />
            ),
        },
        {
            name: 'verified',
            label: translate('Verified'),
            style: {
                width: '6%',
            },
            renderer: ({ cell }) => (
                <Tag
                    size='default'
                    name={cell.content}
                    color={cell.content === 'Yes' ? 'success' : 'grey'}
                />
            ),
        },
    ],
    transactions: [
        {
            name: 'transactionId',
            label: translate('Transaction ID'),
            style: {
                width: '9%',
            },
            canCopy: true,
            quickFilter: true,
        },
        {
            name: 'type',
            label: translate('Type'),
            style: {
                width: '6%',
            },
            quickFilter: true,
            renderer: ({ cell }) => {
                const type = Object.values(transactionConstants.type).find(type => type.id === cell.content);

                return (
                    <Tag
                        size='default'
                        name={type?.name}
                        color={type?.color}
                    />
                );
            },
        },
        {
            name: 'status',
            label: translate('Status'),
            style: {
                width: '6%',
            },
            renderer: ({ cell }) => {
                const status = Object.values(transactionConstants.status).find(status => status.id === cell.content);

                return (
                    <Tag
                        size='default'
                        name={status?.name}
                        color={status?.color}
                    />
                );
            },
            quickFilter: true,
        },
        {
            name: 'amount',
            label: translate('Amount'),
            style: {
                width: '6%',
            },
        },
        {
            name: 'transactionFee',
            label: translate('Fee'),
            style: {
                width: '4%',
            },
        },
        {
            name: 'currency',
            label: translate('Currency'),
            style: {
                width: '5%',
            },
        },
        {
            name: 'userId',
            label: translate('User ID'),
            style: {
                width: '4%',
            },
            canCopy: false,
            quickFilter: true,
        },
        {
            name: 'paymentMethodId',
            label: translate('Payment Method'),
            style: {
                width: '6%',
            },
            canCopy: true,
            quickFilter: true,
        },
        {
            name: 'isAuthorisation',
            label: translate('Is Authorisation'),
            style: {
                width: '5%',
            },
            renderer: ({ cell }) => cell.content ? (
                <Translate>Yes</Translate>
            ) : (
                <Translate>No</Translate>
            ),
        },
        {
            name: 'gatewayPaymentId',
            label: translate('Gateway Payment ID'),
            style: {
                width: '7%',
            },
            canCopy: true,
            quickFilter: true,
        },
        {
            name: 'uid',
            label: translate('UID'),
            style: {
                width: '7%',
            },
            canCopy: true,
            quickFilter: true,
        },
        {
            name: 'reference',
            label: translate('Reference'),
            style: {
                width: '9%',
            },
            canCopy: true,
            quickFilter: true,
        },
        {
            name: 'createdAt',
            label: translate('created at'),
            style: {
                width: '11%',
            },
            renderer: ({ cell }) => formatDate(cell.content),
        },
    ]
};

const filters = {
    screens: [
        {
            keys: ['start', 'end'],
            start: new Date().setDate(1),
            end: new Date(),
            component: 'DatePickerInput',
            type: 'dateRangePicker',
        },
    ],
    campaigns: [
        {
            keys: ['start', 'end'],
            start: new Date().setDate(1),
            end: new Date(),
            component: 'DatePickerInput',
            type: 'dateRangePicker',
        },
        {
            keys: ['status'],
            component: 'DropDown',
            placeholder: translate('Campaign Status'),
            data: Object.values(campaignStatuses),
        },
    ],
    devices: [
        {
            keys: ['workspaceName'],
            component: 'Input',
            superAdminOnly: true,
            placeholder: translate('Workspace'),
        },
        {
            keys: ['workspaceId'],
            component: 'Input',
            superAdminOnly: true,
            placeholder: translate('Workspace ID'),
        },
        {
            keys: ['screenId'],
            component: 'Input',
            placeholder: translate('Screen ID'),
        }
    ],
    invoices: [
        {
            keys: ['invoiceId'],
            component: 'Input',
            placeholder: translate('Invoice ID'),
        },
        {
            keys: ['userId'],
            component: 'Input',
            placeholder: translate('User ID'),
        },
        {
            keys: ['statusId'],
            component: 'DropDown',
            placeholder: translate('Status'),
            data: Object.values(invoiceStatuses),
        },
        {
            keys: ['isProforma'],
            component: 'DropDown',
            placeholder: translate('Proforma'),
            data: booleanDropdown,
        },
        {
            keys: ['holdStatusId'],
            component: 'DropDown',
            placeholder: translate('Hold Status'),
            data: Object.values(invoiceHoldStatuses),
        },
        {
            keys: ['selfWorkspaceOnly'],
            component: 'DropDown',
            placeholder: translate('Self Workspace'),
            data: booleanDropdown,
        },
        {
            keys: ['isLocked'],
            component: 'DropDown',
            placeholder: translate('Locked Transaction'),
            data: booleanDropdown,
        },
        {
            keys: ['verified'],
            component: 'DropDown',
            placeholder: translate('Verified Invoice'),
            data: booleanDropdown,
        }
    ],
    transactions: [
        {
            keys: ['userId'],
            component: 'Input',
            placeholder: translate('User ID'),
        },
        {
            keys: ['transactionId'],
            component: 'Input',
            placeholder: translate('Transaction ID'),
        },
        {
            keys: ['paymentMethodId'],
            component: 'Input',
            placeholder: translate('Payment Method ID'),
        },
        {
            keys: ['gatewayPaymentId'],
            component: 'Input',
            placeholder: translate('Gateway Payment ID'),
        },
        {
            keys: ['reference'],
            component: 'Input',
            placeholder: translate('Reference'),
        },
        {
            keys: ['uid'],
            component: 'Input',
            placeholder: translate('UID'),
        },
        {
            keys: ['type'],
            component: 'DropDown',
            placeholder: translate('Type'),
            data: Object.values(transactionConstants.type),
        },
        {
            keys: ['status'],
            component: 'DropDown',
            placeholder: translate('Status'),
            data: Object.values(transactionConstants.status),
        },
        {
            keys: ['isAuthorisation'],
            component: 'DropDown',
            placeholder: translate('Is Authorisation'),
            data: booleanDropdown,
        },
    ]
};

const noData = {
    nothing: {
        title: translate('There is no data for this... yet!'),
        subTitle: translate('We are doing everything possible to provide you the detailed report.'),
    },
    filtered: {
        title: translate('No data found for your request!'),
        subTitle: translate('Try changing or resetting filters.'),
    },
};

export const reportsRoutes = [
    {
        name: translate('Report by Screens'),
        slug: 'wpReportsScreens',
        icon: 'icon-a-stack',
        subTitle: <Translate>See detailed report based on your screens</Translate>,
        component: (
            <Report
                dataKey='screens'
                keys={keys.screens}
                filters={filters.screens}
                noData={{
                    type: 'screen',
                    ...noData,
                }}
            />
        ),
        url: '/reports/screens',
        guide: {
            rubber: true,
        },
    },
    {
        name: translate('Report by Campaigns'),
        icon: 'icon-a-megaphone',
        slug: 'wpReportsCampaigns',
        subTitle: <Translate>See detailed report based on campaigns running on your screens</Translate>,
        component: (
            <Report
                dataKey='campaigns'
                keys={keys.campaigns}
                filters={filters.campaigns}
                noData={{
                    type: 'campaign',
                    ...noData,
                }}
            />
        ),
        url: '/reports/campaigns',
        guide: {
            rubber: true,
        },
    },
    {
        name: translate('Invoices'),
        icon: 'icon-a-invoice',
        slug: 'wpReportsInvoices',
        subTitle: <Translate>Overview of invoices</Translate>,
        superAdminOnly: true,
        component: (
            <Report
                superAdmin
                dataKey='invoices'
                keys={keys.invoices}
                filters={filters.invoices}
                noData={{
                    type: 'invoices',
                    ...noData,
                }}
            />
        ),
        url: '/reports/invoices',
        guide: {
            rubber: true,
        },
    },
    {
        name: translate('Transactions'),
        icon: 'icon-a-credit-card',
        slug: 'wpReportsTransactions',
        subTitle: <Translate>Overview of transactions</Translate>,
        superAdminOnly: true,
        component: (
            <Report
                superAdmin
                dataKey='transactions'
                keys={keys.transactions}
                filters={filters.transactions}
                noData={{
                    type: 'transactions',
                    ...noData,
                }}
                subItem={transactionDetailsRenderer}
            />
        ),
        url: '/reports/transactions',
        guide: {
            rubber: true,
        },
    },
    {
        name: translate('Device Stats Submissions'),
        icon: 'icon-a-processor',
        slug: 'wpReportsDevices',
        subTitle: <Translate>Overview of device stats submission data</Translate>,
        superAdminOnly: true,
        component: (
            <Report
                superAdmin
                dataKey='device-stats'
                keys={keys.devices}
                filters={filters.devices}
                noData={{
                    type: 'device-stats',
                    ...noData,
                }}
            />
        ),
        url: '/reports/device-stats',
        guide: {
            rubber: true,
        },
    },
];
