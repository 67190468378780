import { conditionalSpread } from 'clyne-core';
import { useSetRecoilState } from 'recoil';
import classNames from 'classnames';
import moment from 'moment/moment';
import React from 'react';

import Tag from '../../../tag';
import Price from '../../../price';

import Background from '../background';
import InlineCellAction from '../inlineCellAction';

import translate from '../../../../utils/translate';

import { moduleContentParamsState } from '../../../../state';

import { dateTimeFormat, dateFormat } from '../../../../constants/construct';

import { cellProps } from './props';

import './index.scss';

const dateFormatter = (date, time) => moment(date).format(time ? dateTimeFormat : dateFormat);

const Cell = props => {
    const {
        fit,
        row,
        cell,
        total,
        colSpan,
        children,
        rowIndex,
        cellIndex,
        autoWidth,
        type = 'td',
    } = props;

    const setModuleContentParams = useSetRecoilState(moduleContentParamsState);

    const sharedProps = {
        colSpan,
        style: {
            ...conditionalSpread(
                cell?.style,
                !!cell?.style),
            ...conditionalSpread({
                width: `${100 / Object.keys({ ...row }).length}%`,
            }, autoWidth),
        },
        className: classNames({
            fit,
        }),
    };

    const content = cell?.content;

    const skeleton = (num = 2) => (
        <div
            className={classNames(
                `skeleton-wave`,
                {
                    wide: rowIndex % num && !(cellIndex % num),
                    small: cellIndex % num,
                }
            )}
        />
    );

    const label = row?.skeleton ? skeleton(3) : (children || (cell && (
        <div className='text-ellipsis'>
            {translate(cell.label)}
        </div>
    )));

    const contentRenderer = children || (cell && (row?.skeleton ? skeleton() : (
        <div className='text-ellipsis'>
            {(total && !cellIndex) ? (
                <strong>
                    {translate('Totals')}
                </strong>
            ) : ((!!content || content === 0 || content === false) && (typeof cell?.renderer === 'function' ? (
                cell.renderer({ cell, row })
            ) : (
                (() => {
                    switch (cell.name.toLowerCase()) {
                        case 'date':
                        case 'time':
                            return dateFormatter(content);
                        case 'id':
                            return (
                                <Tag
                                    size='default'
                                    name={`#${content}`}
                                />
                            );
                        case 'campaignstatustag':
                            return (
                                <Tag
                                    size='default'
                                    name={content?.name}
                                    color={content?.color}
                                />
                            );
                        case 'discountamount':
                        case 'refundamount':
                        case 'estimatedrevenue':
                        case 'actualrevenue':
                        case 'penalties':
                        case 'estimatedprice':
                        case 'actualprice':
                        case 'smartcampaignamount':
                        case 'totalrevenue':
                        case 'revenue':
                        case 'servicefeeworkspace':
                        case 'agentfee':
                        case 'totalagentfee':
                        case 'totalservicefeeworkspace':
                        case 'amount':
                        case 'transactionfee':
                        case 'netrevenue':
                            return (
                                <strong>
                                    <Price number={content >= 0 ? content : content * -1} />
                                </strong>
                            );
                        case 'dates':
                        case 'period':
                        case 'billingperiod':
                            const start = content?.Lower || content?.start || content?.[0];
                            const end = content?.Upper || content?.end || content?.[1];

                            const formatter = date => dateFormatter(date, cell.name.toLowerCase() === 'period');

                            return (
                                <ul className='tc-dates-holder'>
                                    <li className='text-ellipsis'>
                                        {formatter(start)}
                                    </li>
                                    <li className='text-ellipsis'>
                                        {formatter(end)}
                                    </li>
                                </ul>
                            );
                        default:
                            return (
                                <InlineCellAction
                                    {...conditionalSpread({
                                        onCopy: copy => copy(content),
                                    }, !!cell.canCopy)}
                                    {...conditionalSpread({
                                        onQuickFilter: () => setModuleContentParams(val => ({
                                            ...val,
                                            [cell.name]: content,
                                        })),
                                    }, !!cell.quickFilter)}
                                >
                                    {content}
                                </InlineCellAction>
                            );
                    }
                })()
            )))}
        </div>
    )));

    return type === 'card' ? ((total ? !!cellIndex && (!!content || content === 0) : true) && (
        <ul
            className={classNames(
                'mobile-card-table-cell',
                {
                    'span': cell.span && !total,
                }
            )}
        >
            <li className='mct-cell-label'>
                {label}
            </li>
            <li className='mct-cell-body'>
                {contentRenderer}
            </li>
        </ul>
    )) : (type === 'td' ? (
        <td {...sharedProps}>
            {(
                <>
                    {cellIndex === 0 && (
                        <Background />
                    )}
                    {colSpan ? (
                        children
                    ) : (
                        <div className='t-cell t-basic'>
                            {contentRenderer}
                        </div>
                    )}
                </>
            )}
        </td>
    ) : (
        <th {...sharedProps}>
            <div className='t-cell t-head'>
                {label}
            </div>
        </th>
    ));
};

Cell.propTypes = cellProps;

export default Cell;
