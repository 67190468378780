import { useRecoilValue, useSetRecoilState } from 'recoil';
import React, { memo, useMemo, useState } from 'react';
import { isFunction } from 'clyne-core';
import PropTypes from 'prop-types';
import moment from 'moment';

import Icon from '../icon';

import useConnector from '../../hooks/useConnector';

import { dynamicDataLoadingState, dynamicDataTriggerState, workspaceIdState } from '../../state';

import translate from '../../utils/translate';
import humanizeDurationWithTranslation from '../../utils/humanizeDurationWithTranslation';

import './index.scss';

const ScreenOnlineTooltip = memo(props => {
    const {
        id,
        uid,
        aliveAt,
        loading,
        onRefresh,
    } = props;

    const [cache, setCache] = useState(0);
    const workspaceId = useRecoilValue(workspaceIdState);
    const dynamicDataLoading = useRecoilValue(dynamicDataLoadingState);
    const setDynamicDataTrigger = useSetRecoilState(dynamicDataTriggerState);

    const controlled = props.hasOwnProperty('loading') || props.hasOwnProperty('onRefresh');

    const {
        data,
    } = useConnector(`/workspaces/${workspaceId}/screens/${id}/device/${uid}`, {}, {}, false, (!!workspaceId && !!id && !!uid), 'replace', cache);

    const time = useMemo(() => [
        aliveAt,
        data?.lit?.reportedAt,
        data?.lit?.aliveAt,
        data?.lit?.time?.device?.current,
        data?.lit?.time?.user?.current,
        data?.lit?.screen?.changed,
        data?.lit?.network?.lastConnected,
        data?.lit?.network?.lastDisconnected,
    ].filter(Boolean).map(time => new Date(time).valueOf()).sort((a, b) => b - a)[0], [data?.lit, aliveAt]);

    return (
        <div className='screen-online-tooltip'>
            <p className='text-ellipsis'>
                {`${translate('Last online -')} ${humanizeDurationWithTranslation(moment().diff(new Date(time), 'milliseconds'))} ${translate('ago')}`}
            </p>
            <Icon
                size={16}
                onClick={() => {
                    isFunction(onRefresh);
                    setCache(val => val + 1);
                    if (!controlled) {
                        setDynamicDataTrigger(val => val + 1);
                    }
                }}
                type={(controlled ? loading : dynamicDataLoading) ? 'icon-a-loader' : 'icon-a-rotate-cw'}
            />
        </div>
    );
});

ScreenOnlineTooltip.propTypes = {
    id: PropTypes.number,
    uid: PropTypes.string,
    aliveAt: PropTypes.string,
    loading: PropTypes.bool,
    onRefresh: PropTypes.func,
};

export default ScreenOnlineTooltip;
