export const uiKitSizes = [
    'small',
    'default',
    'medium',
    'big',
    'extra-big',
    'extra-large',
];

export const knobAbleProps = [
    'default',
    'knob',
];

export const uiKitCornerRadius = [
    'full',
    'smooth',
];

export const popperConfig = {
    modifiers: (offsetBounding = [0, 0]) => ([
        {
            name: 'offset',
            options: {
                offset: offsetBounding,
            },
        },
        {
            name: 'preventOverflow',
            options: {
                padding: 0,
                rootBoundary: 'window',
            },
        },
        {
            name: 'computeStyles',
            options: {
                gpuAcceleration: false, // true by default
            },
        },
    ]),
    position: [
        'auto-start',
        'auto',
        'auto-end',
        'top-start',
        'top',
        'top-end',
        'right-start',
        'right',
        'right-end',
        'bottom',
        'bottom-start',
        'bottom-end',
        'left-end',
        'left',
        'left-start'
    ],
};
