import { conditionalSpread, fakeArray } from 'clyne-core';
import React, { Fragment, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import To from '../to';
import Tag from '../tag';
import Icon from '../icon';
import Modal from '../modal';
import DropDown from '../dropDown';
import Skeleton from '../skeleton';
import Translate from '../translate';
import RunCommand from '../runCommand';
import Information from '../information';
import GetScreensData from '../getScreensData';

import { deviceActions } from '../screenConsole/data';

import connectionService from '../../services/connectionService';

import { getScreenType, idFormatter, screenURL, successStatus } from '../../helpers';

import { dynamicDataState, genericState } from '../../state';

import addToast from '../../utils/addToast';
import translate from '../../utils/translate';

import './index.scss';

const initialStates = {
    error: [],
    loading: [],
    success: [],
};

const MassActionApply = props => {
    const {
        title,
        status,
        active,
        message,
        onClose,
        screens,
        dropDown,
        onSuccess,
        information,
        columns = 2,
        preprocessUrl,
        dynamicScreens,
        paramsConstructor,
        endpointConstructor,
        closeOnBackdropClick,
    } = props;

    const statesRef = useRef(initialStates);

    const generic = useRecoilValue(genericState);
    const dynamicData = useRecoilValue(dynamicDataState);

    const initialValue = dropDown?.mode === 'multiple' ? (dropDown?.selectAllByDefault ? dropDown?.data?.map(item => item?.[dropDown?.valueSelector]) : []) : '';

    const [command, setCommand] = useState('');
    const [hasError, setHasError] = useState(false);
    const [value, setValue] = useState(initialValue);
    const [states, setStates] = useState(initialStates);
    const [selectedScreens, setSelectedScreens] = useState([]);

    const retryOn = !!states.error.length;

    return (
        <Modal
            title={title}
            active={active}
            onClose={onClose}
            topDivider={false}
            closeOnBackdropClick={closeOnBackdropClick}
            primaryButton={{
                ...conditionalSpread({
                    hero: 'warning',
                }, retryOn),
                loading: !!states.loading.length,
                onClick: () => {
                    const error = (value === deviceActions.runCommand ? !command : (dropDown?.mode === 'multiple' ? !value?.length : !value)) && !!dropDown;
                    setHasError(error);

                    if (!error) {
                        const items = (retryOn ? states.error : (dynamicScreens && selectedScreens?.length ? selectedScreens : screens))?.sort((a, b) => a - b);

                        const forEachSeries = async (iterable, action) => {
                            for (const item of iterable) {
                                await action(item);
                            }
                        };

                        setStates(val => {
                            const value = {
                                ...val,
                                error: [],
                                loading: items,
                            };
                            statesRef.current = value;
                            return value;
                        });

                        forEachSeries(items, id => new Promise(resolve => {
                            new Promise(resolve => {
                                if (preprocessUrl) {
                                    connectionService.getJson(preprocessUrl(id), null, false).subscribe(resolve);
                                } else {
                                    resolve();
                                }
                            }).then(preprocessData => {
                                const onSuccess = () => setStates(val => {
                                    const value = {
                                        error: val.error.filter(i => i !== id),
                                        loading: val.loading.filter(i => i !== id),
                                        success: [
                                            ...val.success,
                                            id,
                                        ],
                                    };
                                    statesRef.current = value;
                                    resolve();
                                    return value;
                                });

                                const onFail = () => setStates(val => {
                                    const value = {
                                        ...val,
                                        loading: val.loading.filter(i => i !== id),
                                        error: [
                                            ...val.error,
                                            id,
                                        ],
                                    };
                                    statesRef.current = value;
                                    resolve();
                                    return value;
                                });

                                if (preprocessUrl && !Array.isArray(preprocessData)) {
                                    onFail();
                                } else {
                                    connectionService.putJson(endpointConstructor(id), paramsConstructor({
                                        id,
                                        value,
                                        command,
                                        preprocessData,
                                    }), false).subscribe(res => {
                                        if (successStatus(res)) {
                                            onSuccess();
                                        } else {
                                            onFail();
                                        }
                                    });
                                }
                            });
                        })).then(() => {
                            const states = statesRef.current;
                            if (states.error.length) {
                                addToast({
                                    type: 'warning',
                                    message: states.error.length === items.length ? (
                                        <Translate>Unfortunately mass apply didn't work, please try again</Translate>
                                    ) : (
                                        <Translate>Some requests failed, please retry</Translate>
                                    ),
                                });
                            } else {
                                addToast({
                                    type: 'success',
                                    message: <Translate>Mass actions has been successfully applied!</Translate>,
                                });
                                setCommand('');
                                setValue(initialValue);
                                setStates(initialStates);
                                statesRef.current = initialStates;
                                onClose && onClose();
                                onSuccess && onSuccess();
                            }
                        });
                    }
                },
                children: retryOn ? (
                    <Translate>Retry Failed</Translate>
                ) : (
                    <Translate>Apply</Translate>
                ),
            }}
            message={{
                type: message?.type,
                children: message?.children,
                node: (
                    <div className='grid gap-2'>
                        {!!dropDown && (
                            <DropDown
                                cornerRadius='full'
                                onChange={val => {
                                    setStates(initialStates);
                                    statesRef.current = initialStates;
                                    setValue(val);
                                    setHasError(!val);
                                }}
                                defaultSelected={value}
                                error={value === deviceActions.runCommand ? false : hasError}
                                {...(dropDown || {})}
                            />
                        )}
                        {value === deviceActions.runCommand && (
                            <RunCommand
                                error={hasError}
                                setCommand={val => {
                                    setCommand(val);
                                    setHasError(!val);
                                    setStates(initialStates);
                                    statesRef.current = initialStates;
                                }}
                            />
                        )}
                        {!dropDown && !dynamicScreens && !information && (
                            <div className='v-divider' />
                        )}
                        <GetScreensData
                            ids={screens}
                            fetchOn='mount'
                        >
                            {({ data, skeleton }) => (
                                <>
                                    {!!dynamicScreens && (
                                        <DropDown
                                            key={22}
                                            mode='multiple'
                                            cornerRadius='full'
                                            valueSelector='id'
                                            labelSelector='name'
                                            initialLoading={skeleton}
                                            onChange={setSelectedScreens}
                                            placeholder={translate('Select the screens')}
                                            defaultSelected={selectedScreens?.length ? selectedScreens : data?.map(item => item.id)}
                                            data={data?.map(item => ({
                                                ...item,
                                                information: item.name,
                                                name: idFormatter(item?.id),
                                            }))}
                                        />
                                    )}
                                    {!!information && (
                                        <Information
                                            type='info'
                                            appearance='light'
                                            {...information}
                                        />
                                    )}
                                    <div
                                        className={classNames(
                                            'grid',
                                            'gap-1',
                                            `cols-${columns}`
                                        )}
                                    >
                                        {(skeleton ? fakeArray(screens.length) : data)?.map(screen => ({
                                            ...screen,
                                            online: dynamicData.deviceStatuses?.online?.some(item => item?.screenId === screen?.id),
                                        }))?.sort((a, b) => a.id - b.id)?.map((screen, index) => {
                                            const type = getScreenType(screen?.typeId, generic)?.icon;
                                            const error = states.error.includes(screen?.id);
                                            const success = states.success.includes(screen?.id);
                                            const loading = states.loading.includes(screen?.id);

                                            return (
                                                <Fragment key={index}>
                                                    {(!(index % columns) && index >= columns) && (fakeArray(columns).map(i => (
                                                        <div
                                                            key={i}
                                                            className='v-divider'
                                                        />
                                                    )))}
                                                    <ul className='mass-action-screen-holder'>
                                                        <li>
                                                            {skeleton ? (
                                                                <Skeleton
                                                                    width={20}
                                                                    height={20}
                                                                    borderRadius={6}
                                                                />
                                                            ) : (
                                                                <Icon
                                                                    size={20}
                                                                    type={error ? 'icon-a-cross-octagon' : success ? 'icon-a-checkbox-square' : loading ? 'icon-a-loader' : type}
                                                                    className={classNames(
                                                                        'mass-action-screen-icon',
                                                                        {
                                                                            error,
                                                                            success,
                                                                        }
                                                                    )}
                                                                />
                                                            )}
                                                        </li>
                                                        <li>
                                                            <div className='mass-action-screen-meta'>
                                                                {skeleton ? (
                                                                    <Skeleton
                                                                        width={18}
                                                                        height={4}
                                                                        trueHeight={8}
                                                                    />
                                                                ) : (
                                                                    <Tag
                                                                        target='_blank'
                                                                        size='extra-small'
                                                                        url={screenURL(screen?.id)}
                                                                        name={idFormatter(screen?.id)}
                                                                    />
                                                                )}
                                                                {!!status && (
                                                                    <div className='mass-action-screen-meta-status'>
                                                                        {skeleton ? (
                                                                            <>
                                                                                <Skeleton
                                                                                    width={4}
                                                                                    height={4}
                                                                                    trueHeight={8}
                                                                                />
                                                                                <Skeleton
                                                                                    width={28}
                                                                                    height={4}
                                                                                    trueHeight={8}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <small
                                                                                    className={classNames(
                                                                                        {
                                                                                            online: screen?.online,
                                                                                        }
                                                                                    )}
                                                                                />
                                                                                <p>
                                                                                    {screen?.online ? (
                                                                                        <Translate>Online</Translate>
                                                                                    ) : (
                                                                                        <Translate>Offline</Translate>
                                                                                    )}
                                                                                </p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {skeleton ? (
                                                                <Skeleton
                                                                    width={100}
                                                                    height={4}
                                                                    trueHeight={8}
                                                                />
                                                            ) : (
                                                                <To
                                                                    target='_blank'
                                                                    url={screenURL(screen?.id)}
                                                                    className='text-ellipsis'
                                                                >
                                                                    {screen?.name}
                                                                </To>
                                                            )}
                                                        </li>
                                                    </ul>
                                                </Fragment>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </GetScreensData>
                    </div>
                ),
            }}
        />
    );
};

MassActionApply.propTypes = {
    title: PropTypes.any,
    status: PropTypes.bool,
    active: PropTypes.bool,
    message: PropTypes.object,
    onClose: PropTypes.func,
    screens: PropTypes.arrayOf(PropTypes.number),
    columns: PropTypes.number,
    dropDown: PropTypes.object,
    onSuccess: PropTypes.func,
    information: PropTypes.object,
    preprocessUrl: PropTypes.func,
    dynamicScreens: PropTypes.bool,
    paramsConstructor: PropTypes.func,
    endpointConstructor: PropTypes.func,
    closeOnBackdropClick: PropTypes.bool,
};

export default MassActionApply;
