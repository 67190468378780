import React, { cloneElement, useEffect, useMemo, useRef, useState } from 'react';
import { default as ReactGoogleMap } from 'google-maps-react-markers';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { conditionalSpread, isFunction } from 'clyne-core';
import useSupercluster from 'use-supercluster';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Point from '../point';
import Loader from '../loader';
import Screen from '../screen';
import MapActions from '../mapActions';

import useDevice from '../../hooks/useDevice';

import integrations from '../../configs/integrations';

import { genericState, loadGoogleScriptState, modeState, userState } from '../../state';

import { getScreenThumbnail, getScreenType, screenURL } from '../../helpers';

import { googleMapConfig } from './props';

import { mapTheme } from './theme';

import './index.scss';

const GoogleMap = props => {
    const {
        onInit,
        screens,
        onClick,
        lat = 0,
        lng = 0,
        children,
        onChange,
        controls,
        zoom = 10,
        selections,
        geolocation,
        focusedItem,
        mapActionsRef,
        onMarkerSelect,
        isScreenLocked,
        repositionOnce,
        setFocusedItem,
        enableItemLocate,
        setScrollToOnById,
    } = props;

    const user = useRecoilValue(userState);
    const mode = useRecoilValue(modeState);
    const generic = useRecoilValue(genericState);
    const setLoadGoogleScript = useSetRecoilState(loadGoogleScriptState);

    useEffect(() => {
        setLoadGoogleScript(true);
    }, []); // eslint-disable-line

    const {
        isMobile,
    } = useDevice();

    const mapRef = useRef(null);
    const map = mapRef.current;

    const [localZoom, setLocalZoom] = useState(zoom);
    const [bounds, setBounds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [allowMapLoad, setAllowMapLoad] = useState(false);
    const [repositioned, setRepositioned] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);

    useEffect(() => {
        if (map) {
            isFunction(onClick, false) && map.addListener('click', e => {
                onClick({ lat: e.latLng.lat(), lng: e.latLng.lng() });
            });
        }
    }, [map]); // eslint-disable-line

    useEffect(() => {
        if (lat && lng && zoom && map && (repositionOnce ? !repositioned : true)) {
            map.setZoom(zoom);
            map.setCenter(new window.google.maps.LatLng(lat, lng));
            setRepositioned(true);
        }
    }, [lat, lng]); // eslint-disable-line

    const points = useMemo(() => screens?.length ? screens?.map(screen => ({
        type: 'Feature',
        properties: {
            screen,
            cluster: false,
        },
        geometry: {
            type: 'Point',
            coordinates: [
                screen.longitude,
                screen.latitude,
            ],
        },
    })) : [], [JSON.stringify(screens)]); // eslint-disable-line

    const { clusters, supercluster } = useSupercluster({
        bounds,
        points,
        zoom: localZoom,
        options: {
            radius: 80,
            maxZoom: 16,
        },
    });

    useEffect(() => {
        const timer = setInterval(() => {
            setAllowMapLoad(!!window.google);
            !!window.google && clearInterval(timer);
        }, 100);
    }, []);

    return allowMapLoad && (
        <div className='absolute-splash google-map-holder'>
            <div className='absolute-splash google-map-c'>
                {loading && (
                    <Loader absolute />
                )}
                <div
                    className={classNames(
                        'google-map-clip',
                        'absolute-splash',
                        {
                            'active': !loading,
                        }
                    )}
                >
                    <ReactGoogleMap
                        apiKey={integrations.google.maps.api.key}
                        loadScriptExternally
                        defaultCenter={{
                            lat,
                            lng,
                        }}
                        status='ready'
                        defaultZoom={zoom}
                        onChange={({ bounds, zoom }) => {
                            setLocalZoom(zoom);
                            const ne = bounds.getNorthEast();
                            const sw = bounds.getSouthWest();
                            setBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()]);
                            (!!mapRef.current && isFunction(onChange, false)) && onChange({
                                ne: {
                                    lat: ne.lat(),
                                    lng: ne.lng(),
                                },
                                sw: {
                                    lat: sw.lat(),
                                    lng: sw.lng(),
                                },
                            }, zoom);
                        }}
                        onGoogleApiLoaded={({ map }) => {
                            setLoading(false);
                            mapRef.current = map;
                            isFunction(onInit, false) && onInit(map);
                        }}
                        key={mode}
                        options={{
                            disableDefaultUI: true,
                            styles: mode === 'dark' ? mapTheme.dark : mapTheme.light,
                            backgroundColor: mode === 'dark' ? 'var(--pageBackground)' : '#c9c9c9',
                            minZoom: googleMapConfig.zoom.min,
                            maxZoom: googleMapConfig.zoom.max,
                        }}
                    />
                    {!!children && cloneElement(children, {
                        map,
                    })}
                    {clusters?.sort((a, b) => a?.id - b?.id)?.map(cluster => {
                        try {
                            const [longitude, latitude] = cluster.geometry.coordinates;

                            const {
                                screen,
                                cluster: isCluster,
                                point_count: pointCount
                            } = cluster.properties;

                            const leaves = isCluster && cluster?.id ? supercluster.getLeaves(cluster?.id, Infinity) : [];

                            const clusterId = leaves.map(cluster => cluster.properties.screen.id)?.sort((a, b) => a - b)?.join('-');

                            return (
                                <Point
                                    map={map}
                                    lat={latitude}
                                    lng={longitude}
                                    key={isCluster ? `cluster-${clusterId}` : `screen-${screen?.id}`}
                                    {...(isCluster ? ({
                                        type: 'circle',
                                        children: pointCount,
                                        size: 10 + (pointCount / points?.length) * 70,
                                        selected: leaves.some(cluster => selections?.includes(cluster.properties.screen.id)),
                                        focused: leaves.some(cluster => cluster.properties.screen.id === focusedItem?.id && focusedItem?.source === 'item'),
                                        color: leaves.every(cluster => cluster.properties.screen.typeId === 4) ? 'taxi' : 'brand',
                                        gradient: leaves.some(cluster => cluster.properties.screen.typeId === 4) ? ['taxi'] : null,
                                        selectedIcon: isFunction(isScreenLocked, false) && leaves.every(cluster => isScreenLocked(cluster.properties.screen.id)) ? 'icon-a-lock' : 'icon-a-check',
                                        onClick: () => {
                                            const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
                                            map.setZoom(expansionZoom);
                                            map.setCenter(new window.google.maps.LatLng(latitude, longitude));
                                        },
                                    }) : ({
                                        selected: selections?.includes(screen?.id),
                                        icon: getScreenType(screen?.typeId, generic)?.icon,
                                        color: getScreenType(screen?.typeId, generic)?.color,
                                        focused: screen?.id === focusedItem?.id && focusedItem?.source === 'item',
                                        selectedIcon: isFunction(isScreenLocked, false) && isScreenLocked(screen?.id) ? 'icon-a-lock' : 'icon-a-check',
                                        [isMobile ? 'onClick' : 'onMouseEnter']: () => {
                                            isMobile && setScrollToOnById(screen?.id);
                                            isFunction(setFocusedItem, false) && setFocusedItem({
                                                id: screen?.id,
                                                source: 'marker',
                                            });
                                        },
                                        ...conditionalSpread({
                                            onMouseLeave: () => isFunction(setFocusedItem, false) && setFocusedItem({}),
                                            onClick: () => {
                                                if (screen?.comingSoon) {
                                                    setScrollToOnById(screen?.id);
                                                } else {
                                                    isFunction(onMarkerSelect, false) && onMarkerSelect(screen);
                                                }
                                            },
                                        }, !isMobile),
                                        children: (
                                            <Screen
                                                favoriteAble
                                                mapRef={mapRef}
                                                id={screen?.id}
                                                target='_blank'
                                                name={screen?.name}
                                                appearance='compact'
                                                rating={screen?.rating}
                                                to={screenURL(screen?.id)}
                                                setFocusedItem={setFocusedItem}
                                                price={screen?.averagePricePerMonth}
                                                setScrollToOnById={setScrollToOnById}
                                                image={getScreenThumbnail(screen?.images)}
                                                locked={isFunction(isScreenLocked, false) && isScreenLocked(screen?.id)}
                                            />
                                        ),
                                    }))}
                                />
                            );
                        } catch (e) {
                            return null;
                        }
                    })}
                    {!!currentLocation && (
                        <Point
                            map={map}
                            lat={currentLocation.lat}
                            lng={currentLocation.lng}
                            key={`${currentLocation.lng}${currentLocation.lat}`}
                            color='dark'
                            type='circle'
                            {...(user?.avatarURL ? ({
                                avatar: user?.avatarURL,
                            }) : ({
                                icon: 'icon-a-user',
                            }))}
                        />
                    )}
                </div>
            </div>
            <MapActions
                map={map}
                controls={controls}
                children={children}
                localZoom={localZoom}
                geolocation={geolocation}
                mapActionsRef={mapActionsRef}
                enableItemLocate={enableItemLocate}
                setCurrentLocation={setCurrentLocation}
            />
        </div>
    );
};

GoogleMap.propTypes = {
    lat: PropTypes.number,
    lng: PropTypes.number,
    zoom: PropTypes.number,
    onInit: PropTypes.func,
    screens: PropTypes.array,
    onClick: PropTypes.func,
    children: PropTypes.any,
    onChange: PropTypes.func,
    controls: PropTypes.bool,
    selections: PropTypes.array,
    focusedItem: PropTypes.object,
    geolocation: PropTypes.bool,
    mapActionsRef: PropTypes.any,
    onMarkerSelect: PropTypes.func,
    isScreenLocked: PropTypes.func,
    setFocusedItem: PropTypes.func,
    repositionOnce: PropTypes.bool,
    enableItemLocate: PropTypes.bool,
    setScrollToOnById: PropTypes.func,
};

export default GoogleMap;
