import { setRecoil } from 'recoil-nexus';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/hy-am';
import 'moment/locale/en-in';

import { setLSItem } from '../helpers';

import languages from '../configs/languages';

import { languageState } from '../state';

class TranslationService {
    constructor() {
        this._languages = new BehaviorSubject({});
        this._currentLanguage = new BehaviorSubject(null);
        this._reversedTranslations = new BehaviorSubject({});
    }

    setLanguage(lang) {
        const defaultLanguage = languages.find(lang => lang.default);
        const language = languages.find(l => l.code === lang.toLowerCase()) || defaultLanguage;
        new Promise(resolve => {
            import(`../translations/${language.code}.js`).then(val => {
                this._languages.next({
                    ...this._languages.getValue(),
                    [language.code]: val[language.code],
                });
                resolve();
            });
        }).then(() => {
            setLSItem('language', language.code);
            setRecoil(languageState, language);
            this._currentLanguage.next(language.code);
            document.documentElement.setAttribute('lang', language.code);
            moment.locale(language.code === 'hy' ? 'hy-am' : language.code);
        });
    }

    t(text, replaceMap) {
        let finalText = text;
        const currentLanguage = this._currentLanguage.getValue();
        const language = this._languages.getValue()?.[currentLanguage];
        if (typeof text === 'string') {
            if (currentLanguage && language) {
                const reversedTranslations = this._reversedTranslations.getValue();
                finalText = language[text.toLowerCase()] || language?.[reversedTranslations?.[text]?.toLowerCase()] || text;
            }

            if (replaceMap) {
                const textKeys = text.match(/([_][A-Z][^a-z])\w+/g);
                if (textKeys?.length) {
                    finalText = textKeys.reduce((acc, key) => {
                        return acc.replace(key, (replaceMap[key] || replaceMap[key] === 0) ? `${replaceMap[key]}` : '');
                    }, finalText);
                }
            }

            this._reversedTranslations.next({
                ...this._reversedTranslations.getValue(),
                [finalText]: text,
            });

            return finalText;
        }

        return finalText;
    }

    get currentLanguage() {
        return this._currentLanguage;
    }
}

export default new TranslationService(); // eslint-disable-line
