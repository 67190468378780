import { createPortal } from 'react-dom';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../icon';
import Tooltip from '../tooltip';
import Translate from '../translate';

import addToast from '../../utils/addToast';

import useDevice from '../../hooks/useDevice';

import { googleMapConfig } from '../googleMap/props';

import './index.scss';

const MapActions = props => {
    const {
        map,
        controls,
        children,
        localZoom,
        geolocation,
        mapActionsRef,
        enableItemLocate,
        setCurrentLocation,
    } = props;

    const {
        isMobile,
    } = useDevice();

    const [currentLocationLoading, setCurrentLocationLoading] = useState(false);

    const setCurrentLocationCallback = (lat, lng) => {
        if (lat && lng) {
            map.setZoom(17);
            setCurrentLocation({ lat, lng });
            map.setCenter(new window.google.maps.LatLng(lat, lng));
        } else {
            addToast({
                type: 'error',
                message: <Translate>Please make sure you have enabled geolocation services.</Translate>
            });
        }
        setCurrentLocationLoading(false);
    };

    const getCurrentLocation = () => {
        setCurrentLocationLoading(true);
        navigator.geolocation.getCurrentPosition(coordinates => {
            setCurrentLocationCallback(coordinates.coords.latitude, coordinates.coords.longitude);
        }, () => setCurrentLocationCallback(), {
            enableHighAccuracy: true,
        });
    };

    const handleZoom = increase => map.setZoom(increase ? localZoom + 1 : localZoom - 1);

    const currentCenter = map?.getCenter();

    const actions = (
        <ul className='map-actions'>
            {(enableItemLocate && children && !Array.isArray(children)) && (
                <li
                    className={classNames({
                        'hide': currentCenter?.lat() === children.props.lat && currentCenter?.lng() === children.props.lng && map?.getZoom() === 16,
                    })}
                >
                    <Tooltip
                        position='auto'
                        key={`${currentCenter?.lat()}${currentCenter?.lng()}`}
                        content={<Translate>Locate Item</Translate>}
                    >
                        <button
                            type='button'
                            onClick={() => {
                                map.setCenter({
                                    lat: children.props.lat,
                                    lng: children.props.lng,
                                });
                                map.setZoom(16);
                            }}
                        >
                            <Icon
                                size={isMobile ? 20 : 22}
                                type='icon-a-compass'
                            />
                        </button>
                    </Tooltip>
                </li>
            )}
            {(geolocation && !!navigator.geolocation) && (
                <li>
                    <Tooltip
                        position='auto'
                        key={`${currentCenter?.lat()}${currentCenter?.lng()}`}
                        content={<Translate>My Location</Translate>}
                    >
                        <button
                            type='button'
                            className={classNames(
                                {
                                    'pointer-events-none': currentLocationLoading,
                                }
                            )}
                            onClick={() => getCurrentLocation()}
                        >
                            <Icon
                                size={isMobile ? 18 : 20}
                                type={currentLocationLoading ? 'icon-a-loader' : 'icon-direction'}
                            />
                        </button>
                    </Tooltip>
                </li>
            )}
            {!!controls && (
                <li>
                    <button
                        type='button'
                        onClick={() => handleZoom(true)}
                        disabled={localZoom === googleMapConfig.zoom.max}
                    >
                        <Icon
                            size={isMobile ? 20 : 24}
                            type='icon-a-plus'
                        />
                    </button>
                    <button
                        type='button'
                        onClick={() => handleZoom(false)}
                        disabled={localZoom === googleMapConfig.zoom.min}
                    >
                        <Icon
                            size={isMobile ? 20 : 24}
                            type='icon-a-minus'
                        />
                    </button>
                </li>
            )}
        </ul>
    );

    return ((geolocation && !!navigator.geolocation) || controls) && (
        mapActionsRef?.current ? createPortal(actions, mapActionsRef?.current) : actions
    );
};

MapActions.propTypes = {
    map: PropTypes.object,
    controls: PropTypes.bool,
    children: PropTypes.any,
    localZoom: PropTypes.number,
    geolocation: PropTypes.bool,
    mapActionsRef: PropTypes.object,
    enableItemLocate: PropTypes.bool,
    setCurrentLocation: PropTypes.func,
};

export default MapActions;
