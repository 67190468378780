import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Radio from '../radio';
import Checkbox from '../checkbox';

import translate from '../../utils/translate';

import './index.scss';

const Option = props => {
    const {
        icon,
        name,
        check,
        radio,
        onClick,
        selected,
        checkbox,
        renderer,
        information,
        highlighted,
        size = 'big',
        setItemHeight,
        additionalPadding,
    } = props;

    const optionRef = useRef(null);

    useEffect(() => {
        optionRef.current && setItemHeight && setItemHeight(optionRef.current.clientHeight);
    }, [setItemHeight]);

    const hasLeftActions = icon || checkbox || radio;
    const hasRightActions = check;

    return (
        <ul
            ref={optionRef}
            onClick={onClick}
            className={classNames(
                `option-holder`,
                `s-${size}`,
                {
                    selected,
                    highlighted,
                    'custom': renderer,
                    'c-start': hasLeftActions,
                    'c-end': hasRightActions,
                    'has-cr': checkbox || radio,
                    'has-ap': additionalPadding,
                }
            )}
        >
            {hasLeftActions && (
                <li className='o-c-holder'>
                    {!!icon?.type && (
                        <div className='option-icon'>
                            <Icon
                                type={icon.type}
                                size={icon.size}
                                multiColor={icon.multiColor}
                            />
                        </div>
                    )}
                    {checkbox && (
                        <Checkbox checked={selected}/>
                    )}
                    {radio && (
                        <Radio checked={selected}/>
                    )}
                </li>
            )}
            <li className='o-content'>
                <div className='text-ellipsis'>
                    {renderer ? renderer : translate(name)}
                </div>
                {information && (
                    <div className='o-information text-ellipsis'>{information}</div>
                )}
            </li>
            {hasRightActions && (
                <li className='o-c-holder'>
                    {check && (
                        <div className='option-check'>
                            <Icon
                                size={22}
                                type='icon-a-check'
                            />
                        </div>
                    )}
                </li>
            )}
        </ul>
    );
};

Option.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
        type: PropTypes.string,
        size: PropTypes.number,
        onClick: PropTypes.func,
        skeleton: PropTypes.bool,
        className: PropTypes.string,
        multiColor: PropTypes.bool,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
    })]),
    name: PropTypes.any,
    size: PropTypes.oneOf([
        'medium',
        'big',
    ]),
    check: PropTypes.bool,
    radio: PropTypes.bool,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    checkbox: PropTypes.bool,
    renderer: PropTypes.any,
    information: PropTypes.any,
    highlighted: PropTypes.bool,
    setItemHeight: PropTypes.func,
    additionalPadding: PropTypes.bool,
};

export default Option;
